// Card.js
import React from 'react';

import '../css/card.css';

const Card = ({ imageUrl, title, description }) => (
  <div className="card">
    <img src={imageUrl} alt={title} className="card-image" />
    <div className="card-content">
      <h2 className="card-title">{title}</h2>
      <p className="card-description">{description}</p>
    </div>
  </div>
);

export default Card;
