import React, {useEffect, useState} from 'react';
import '../css/BlogPage.css';
import avatar1 from '../images/Furloop_Favicon.png';

import { Link } from 'react-router-dom';


const BlogPage = () => {
  
  const [articles, setArticles] = useState([]);
  useEffect(() => {
    fetch('https://cms.furloop.io/api/articles?populate=*')
      .then((response) => response.json())
      .then((data) => {
        console.log('fetched data:', data);
        setArticles(data.data);
      })
      .catch((error) => console.error('Error fetching data:', error));
  }, []);
  const pageStyle = {
    // backgroundImage: 'url(../images/Blog_Background.svg)',
    backgroundSize: 'cover',
    backgroundPosition: 'center',
    backgroundRepeat: 'no-repeat',
    minHeight: '100vh',
  };

   // Function to truncate title to a word limit
   const truncateText = (text, wordLimit) => {
    if (!text) return "";
    const words = text.split(" ");
    if (words.length > wordLimit) {
      return words.slice(0, wordLimit).join(" ") + "...";
    }
    return text;
  };

  return (

    

    <div style={pageStyle}>
      <div className='blog-bg'>

        <section className="main-section-header">
          <div className="main-container">
            <div className="content-wrap_center">
              <div id="about-us-content" className="content-wrap_center mw-800">
                <div className="subheader cta-color">Our Blog</div>
                <h1 className="h1 card-h1">Resources and insights</h1>
                <p className="paragraph">
                The latest industry news, interviews, technologies, and resources.
                </p>
              </div>
            </div>
          </div>
        </section>

        <section>
            <div className='container'>
              <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6 p-4">
                {articles.map((article) => (
                  <div 
                    key={article.id} 
                    className="card bg-white shadow-lg rounded-lg overflow-hidden h-[500px] flex flex-col"
                  >
                    {/* Card Image */}
                    <div className="aspect-w-16 aspect-h-9 w-full h-48">
                      {article.cover?.formats?.thumbnail?.url ? (
                        <img
                          className="w-full h-full object-cover"
                          src={`https://cms.furloop.io${article.cover.formats.thumbnail.url}`}
                          alt={article.attributes?.title || 'Default Title'}
                          sizes="90vw"
                          srcSet={`https://cms.furloop.io${article.cover.formats.thumbnail.url} 500w,
                                  https://cms.furloop.io${article.cover.formats.thumbnail.url} 800w,
                                  https://cms.furloop.io${article.cover.formats.thumbnail.url} 1080w,
                                  https://cms.furloop.io${article.cover.formats.thumbnail.url} 1600w,
                                  https://cms.furloop.io${article.cover.formats.thumbnail.url} 1920w`}
                          loading="lazy"
                        />
                      ) : (
                        <img
                          className="w-full h-full object-cover"
                          src="path/to/default-image.jpg"
                          alt="Default cover"
                          loading="lazy"
                        />
                      )}
                    </div>
                    
                    {/* Card Content */}
                    <div className="p-6 flex flex-col flex-grow">
                      <h2 className="card-title py-4">
                        <Link 
                          to={`/blog/${article.slug}`} 
                          className="read-more hover:text-indigo-600 line-clamp-2"
                        >
                          {truncateText(article.title || 'Untitled', 20)}
                        </Link>
                      </h2>
                      
                      <p className="text-gray-600 flex-grow line-clamp-3">
                        {truncateText(article.description || '', 20)}
                      </p>
                    </div>

                    <div className="media row my-4">
                      <div className="col-md-2 col-2">
                        {/* Author Image */}
                        <img
                          className="ml-3"
                          src={avatar1}
                          alt={article.author?.name || 'Unknown Author'}
                          sizes="90vw"
                          loading="lazy"
                        />
                      </div>
                      <div className="media-body col-md-6 col-6">
                        <h5 className="mt-0 card-text-writer">
                          {article.author?.name || 'Unknown Author'}
                        </h5>
                        <p className="card-date">
                           {article.createdAt ? new Date(article.createdAt).toLocaleDateString('en-GB', { day: 'numeric', month: 'short', year: 'numeric' }) : 'No Date'}
                        </p>
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            </div>


        </section>




      </div>
    </div>
  );
};

export default BlogPage;
