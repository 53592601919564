import React, { useState, useEffect } from "react";
import "../css/Brand.css";
import "../css/webflow.css";
import "../css/normalize.css";
import "../css/manmade-cycle-main.webflow.css";

import FixUpp from "../images/brand_logo/FixUpp.svg";
import Harris_Technology from "../images/brand_logo/Harris_Technology.svg";
import ManMade from "../images/brand_logo/ManMade.svg";
import PisenLogo from "../images/brand_logo/PisenLogo.svg";

const logos = [FixUpp, Harris_Technology, ManMade, PisenLogo];

const TrustedBy = () => {
  return (
    <div className="bg-white py-6 sm:py-12">
      <div className="mx-auto max-w-7xl px-6 lg:px-8">
        <div className="flex flex-col lg:flex-row items-center justify-between">
          <h5 className="text-center lg:text-left text-lg font-semibold bg-clip-text text-transparent bg-gradient-to-r from-[#3B2B52] to-[#B82FAB] mb-6 lg:mb-0">
            Trusted by
          </h5>
          <div className="mx-auto lg:mx-0 mt-10 lg:mt-0 grid max-w-lg grid-cols-2 items-center gap-x-8 gap-y-10 sm:max-w-xl sm:grid-cols-3 sm:gap-x-10 lg:max-w-none lg:grid-cols-4">
            {logos.map((logo, index) => (
              <img
                key={index}
                className="max-h-12 w-full object-contain"
                src={logo}
                alt={`Logo ${index}`}
              />
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default TrustedBy;
