import React from "react";
import '../css/webflow.css';
import '../css/normalize.css';
import '../css/manmade-cycle-main.webflow.css';
import { FiTrendingUp, FiArchive, FiCreditCard, FiBarChart2, FiUsers } from "react-icons/fi";
import { TbTicket } from "react-icons/tb";
import { MdOutlineRocketLaunch } from "react-icons/md";
import { PiDevicesLight } from "react-icons/pi";
import { IoCloudDoneOutline } from "react-icons/io5";
import { GoGoal } from "react-icons/go";
import { BsLightningCharge } from "react-icons/bs";

const features = [
    {
      icon: <MdOutlineRocketLaunch className="text-purple-700 text-2xl" />,
      title: "Boost Revenue and Profitability",
      description: "Shop our latest Refurbished MacBook, Mac, iPhone, iPad, iWatch, and Windows ",
      link: "#",
    },
    {
      icon: <FiTrendingUp className="text-purple-700 text-2xl" />,
      title: "Increase Efficiency",
      description: "Trade-in your devices in 3 easy steps, We accept any Used/Broken Devices",
      link: "#",
    },
    {
      icon: <BsLightningCharge className="text-purple-700 text-2xl" />,
      title: "Faster Turnaround",
      description: "Apple-certified repair provider with genuine parts and technicians",
      link: "#",
    },
    {
      icon: <GoGoal className="text-purple-700 text-2xl" />,
      title: "Greater Accuracy",
      description: "Retrieve devices like laptops and accessories from employees, domestic or international.",
      link: "#",
    },
    {
      icon: <PiDevicesLight className="text-purple-700 text-2xl" />,
      title: "Accessible from any device",
      description: "Most secure way to sanitise or erase your data-bearing devices.",
      link: "#",
    },
    {
      icon: <IoCloudDoneOutline className="text-purple-700 text-2xl" />,
      title: "Automated data import",
      description: "Most secure way to sanitise or erase your data-bearing devices.",
      link: "#",
    },

  ];

  const RepairManagement = () => {
    return (
      <section className="py-16 px-6 max-w-5xl mx-auto">
        <h2 className="text-center text-2xl md:text-3xl font-medium text-[#01017C] pb-5">
        Easy Trade-in integrations
        </h2>

        <div className="mt-8 space-y-6">
          {features.map((feature, index) => (
            <div key={index} className="grid grid-cols-1 md:grid-cols-12 gap-4 items-center border-b border-gray-300 pb-4">
              {/* Icon + Title (col-md-6) */}
              <div className="flex items-center gap-3 col-span-12 md:col-span-6">
                <span className="w-8 h-8 flex items-center justify-center">
                  {feature.icon}
                </span>
                <h3
                  className={`text-lg font-semibold text-gray-900 ${
                    feature.link ? "cursor-pointer hover:bg-gradient-to-r hover:from-[#B97EE9] hover:to-[#5D51FF] hover:bg-clip-text hover:text-transparent" : ""
                  }`}
                >
                  {feature.title}
                </h3>
              </div>

              {/* Description (col-md-6) */}
              <div className="col-span-12 md:col-span-6 text-gray-600 text-sm">
                {feature.description}
              </div>
            </div>
          ))}
        </div>
      </section>
    );
  };

export default RepairManagement;
