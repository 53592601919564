import React, { useState } from 'react';
import '../css/webflow.css';
import '../css/normalize.css';
import '../css/manmade-cycle-main.webflow.css'; 

const FAQSection = () => {
  const [activeIndices, setActiveIndices] = useState([]);
  const toggleFAQ = (index) => {
    if (activeIndices.includes(index)) {
      setActiveIndices(activeIndices.filter(i => i !== index));
    } else {
      setActiveIndices([...activeIndices, index]);
    }
  };

 
  const faqData = [
    {
      question: 'How does FurLoop streamline repair and trade-in management?',
      answer: 'FurLoop offers an integrated solution for repair and trade-in business, allowing you to manage your tickets, orders, and customers in one place.',
    },
    {
      question: 'What repair management features does FurLoop offer?',
      answer: 'We provide point of sale dashboard, inventory management, and a dashboard for managing your repair tickets and quotes.',
    },
    {
      question: 'What trade-in order management features does FurLoop offer?',
      answer: 'We offer a trade-in management dashboard that lets you set custom pricing and manage the products available for trade-in.',
    },
    {
      question: 'Do you have a customer-facing website where trade-in orders can be placed?',
      answer: 'We offer a customer-facing widget that allows your customers to create sell orders for their trade-in devices, and a customer-facing website for repairs will be available soon.',
    },
    {
      question: 'Is your website accessible?',
      answer: 'Yes, you can acces our admin dashboard anytime/ anywhere.',
    },
    {
      question: 'Do you provide a demo?',
      answer: 'Yes, we do. Please request a demo through help@furloop.io, and our team will walk you through the dashboard and embedding process.',
    },
  ];


  const firstColumnData = faqData.slice(0, 3);
  const secondColumnData = faqData.slice(3);

  return (
    
    <>
    
 <div className="main-section-header">
      <div className="main-container pb-20">
        <div id="w-node-_282f81a1-557a-1f3d-aa2b-6f5ffdf3c5fa-6232a524" className="content-wrap pt-10">
          <h2 className="trade-in-header">Have a question? Get an answer!</h2>
          <div className="spacer_xs"></div>
          <p className="paragraph">
            For any additional questions, feel free to contact us via the form provided above.
          </p>
        </div>
        <div className="spacer_xxl"></div>
        <div className="grid_2-4 vertical-mobile">
          {faqData.map((faq, index) => (
            <div key={index} className="wrap_accordion">
              <div className="faq-item">
                <a
                  className="faq-question w-inline-block"
                  onClick={() => toggleFAQ(index)}
                >
                  <div className='text-left pr-2.5'><strong>{faq.question}</strong></div>
                  <div className="wrap_p-m">
                  <div className={`minus ${activeIndices.includes(index) ? 'visible' : ''}`}></div>
                <div className={`plus ${activeIndices.includes(index) ? 'hidden' : 'visible'}`}></div>
             </div>
                </a>
                {activeIndices.includes(index) && (
                  <div className="faq-answer">
                    <p className="faq-p">{faq.answer}</p>
                  </div>
                )}
              </div>
            </div>
          ))}
        </div>
      </div>

      
    </div>

    


    
    </>
  );
};

export default FAQSection;
