import React from 'react';
import '../css/HeroSection.css';
import '../css/webflow.css';
import '../css/normalize.css';
import '../css/manmade-cycle-main.webflow.css';
import { Link } from 'react-router-dom';
import { useState, useEffect } from 'react';
import repairDashboard from '../images/repair dashboard.png';  // Adjust the path as needed



const HeroSection = () => {
  

  // List of words to rotate
  const words = ['Repairs', 'Orders', 'Quotes','Trade Ins'];
  const [currentWordIndex, setCurrentWordIndex] = useState(0);

  // Change the word every 2 seconds (2000 milliseconds)
  useEffect(() => {
    const intervalId = setInterval(() => {
      setCurrentWordIndex((prevIndex) => (prevIndex + 1) % words.length);
    }, 1500); // Adjust timing here

    return () => clearInterval(intervalId); // Cleanup the interval on unmount
  }, [words.length]);


  return (

<div id="main-section-edit" className="main-section-header-title">
        <section className="section-intro">
          <div className="w-layout-blockcontainer container-3 w-container">
            <h1 className="heading-2">Integrated Solution for your <br /><span className="gradeint-header">{words[currentWordIndex]}</span> Needs</h1>
            <div className="w-layout-blockcontainer container-5 w-container">
              <div className="div-block-6"><img src="https://cdn.prod.website-files.com/648422913bdb661f6232a4c4/669e56eeb1d5f08a90d47d7d_Icon.svg" loading="lazy" alt="" /></div>
            </div>
          </div>
          <div className="text-block">Level Up From Excel and Google Sheets! <br /> Manage your repairs, orders, and quotes in one place.</div>
         
        </section>
        <div className="div-block-2"></div>
        <form className="email-signup-form">
          <div className="input-button-container">
            <input 
              type="email" 
              placeholder="Enter your email" 
              className="email-input custom-input custom-button"
            />
            
            <Link to="/sign-up-today" type="button" className="btn btn-primary custom-button" style={{ backgroundColor: '#3B2B52', color: 'white' }}>Get Free Trial</Link>
            

          </div>
        </form>


        <div className="div-block-4"></div>
        <div className="w-layout-blockcontainer container-6 w-container">
          <div className="div-block-hero"><img src="https://cdn.prod.website-files.com/648422913bdb661f6232a4c4/669e5aa2dff36e32b9051c06_Icon%20(1).svg" loading="lazy" alt="" /></div>
        </div>
        <div className="div-block-3"></div>
      </div>


  );
};

export default HeroSection;
