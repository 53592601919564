import React from 'react';
import HeroSection from './HeroSection';
import Brand from './Brand';
import TrustedBy from './TrustedBy';
import MainSection from './MainSection';
import Repair from './Repair';
import FeatureSection from './FeatureSection';
import RepairManagement from './RepairManagement';
import PriceSection from './PriceSection';
import ContactSection from './ContactSection';
import FAQSection from './FAQSection';
import BlogSection from './BlogSection';


function Home() {
  return (
    <div>
    <HeroSection />
    {/* <Brand /> */}
    <TrustedBy/>
    {/* <MainSection /> */}
    <Repair/>
    {/* <FeatureSection /> */}
    <RepairManagement />
    <PriceSection />
    <ContactSection />
    <FAQSection />
    {/* <BlogSection/> */}
    </div>

  );
}

export default Home;
