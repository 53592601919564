import React, { useState } from 'react';

import Card from './card';

// CSS imports (make sure the paths are correct)
import '../css/Repair.css';

// Importing images
import card1 from "../images/real-time-tracking1.svg";
import card2 from "../images/ticket-logging2.svg";
import card3 from "../images/labor-reduction3.svg";
import card4 from "../images/analytics4.svg";
import tradeInCard1 from "../images/boost5.svg";
import tradeInCard2 from "../images/build6.svg";
import tradeInCard3 from "../images/simplify7.svg";
import tradeInCard4 from "../images/optimize8.svg";

// Card data
const repairCards = [
  { image: card1, title: "Monitor Repairs in Real-Time", description: "Stay updated on the status of each repair, from parts used to completion time, enhancing transparency and satisfaction." },
  { image: card2, title: "Quick Repair Logging", description: "Effortlessly generate detailed repair tickets that track repair status, and provide anytime, anywhere access to records—eliminating the need for manual spreadsheets or paper logs." },
  { image: card3, title: "Reduce the need for extra labour", description: "Being able to visualize the data, from the number of repairs and repair time to stock on hand, helps you manage multiple stores with ease." },
  { image: card4, title: "Data-Driven Insights", description: "Analyse repair completion times and optimize workflows to boost efficiency, streamline payment reconciliation, identify and mitigate fraudulent activities." },
];

const tradeInCards = [
  { image: tradeInCard1, title: "Boost Your Bottom Line", description: "Integrating buybacks creates a new revenue stream by allowing customers to trade in old devices, attracting new buyers and enabling resale of returned products." },
  { image: tradeInCard2, title: "Build Loyalty", description: "A buyback program fosters trust and loyalty, offering customers an easy upgrade path while earning credit for their old devices." },
  { image: tradeInCard3, title: "Simplify Processes", description: "Automated buyback integration reduces manual work and errors, handling instant quotes, and returns smoothly while keeping customer updated with real life notifications. " },
  { image: tradeInCard4, title: "Optimize Stock", description: "A buyback system lets you receive and refurbish used devices, helping reduce excess stock, improve turnover, and manage product life cycles more efficiently." },
];

const Repair = () => {
  const [currentCards, setCurrentCards] = useState(repairCards);

  return (
    <section className="bg-gradient-to-b from-gray-100 to-white py-16 px-6 bg-repair">
      <div className="text-center">
        <h2 className="text-3xl md:text-4xl font-medium text-[#3B2B52]">Designed for Repair Business</h2>
        <p className="text-gray-600 mt-2 font-normal max-w-md mx-auto">
          Feature-packed platform to unify, simplify, and oversee your repair business operations.
        </p>

        <div className="mt-6 flex justify-center gap-4">
        <button
  style={{
    padding: '6px 20px',
    borderRadius: '9999px',
    backgroundColor: currentCards === repairCards ? '#4538FF' : '#FFFFFF',
    color: currentCards === repairCards ? '#FFFFFF' : '#4B5563',
    border: '1px solid #4538FF'
  }}
  onClick={() => setCurrentCards(repairCards)}
>
  New Repair Features
</button>
<button
  style={{
    padding: '6px 20px',
    borderRadius: '9999px',
    backgroundColor: currentCards === tradeInCards ? '#4538FF' : '#FFFFFF',
    color: currentCards === tradeInCards ? '#FFFFFF' : '#4B5563',
    border: '1px solid #4538FF'
  }}
  onClick={() => setCurrentCards(tradeInCards)}
>
  Trade-in Features
</button>

        </div>
      </div>

      <div className="mt-12 grid grid-cols-1 sm:grid-cols-2 md:grid-cols-2 lg:grid-cols-4 gap-6 max-w-7xl mx-auto">
        {currentCards.map((card, index) => (
          <Card key={index} imageUrl={card.image} title={card.title} description={card.description} />
        ))}
      </div>
    </section>
  );
};

export default Repair;
